import Vue from 'vue'

Vue.prototype.$addBreadcrumb = function(title) {
  const breadcrumbList = JSON.parse(sessionStorage.getItem('breadcrumbList') || '[]')
  if (breadcrumbList.length === 100) return   
  const { fullPath } = this.$route
  if (breadcrumbList.length) {
      const flag = breadcrumbList[breadcrumbList.length - 1].fullPath === fullPath
      if (flag) return
  }
  const obj = {
      title,
      fullPath
  }
  sessionStorage.setItem('breadcrumbList', JSON.stringify([...breadcrumbList, obj]))
}
Vue.prototype.$clearBreadcrumb = function() {
  sessionStorage.setItem('breadcrumbList', '[]')
}

export const sliceBreadcrumb = function(path) {
  const breadcrumbList = JSON.parse(sessionStorage.getItem('breadcrumbList') || '[]')
  let index = -1
  breadcrumbList.forEach((r, i) => {
      if (r.fullPath === path) index = i
  })
  sessionStorage.setItem('breadcrumbList', JSON.stringify(index === -1 ? [...breadcrumbList] : [...breadcrumbList.slice(0, index)]))
}

Vue.prototype.$sliceBreadcrumb = sliceBreadcrumb
