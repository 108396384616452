<template>
    <div
        class="modal"
        :class="[modalClassName]"
        v-if="isShow"
        @click.self="clickModal"
    >
        <div class="confirm-container">
            <img class="confirm-close-button" v-if="closeImageShow" @click="hide" src="@/assets/img/close.png">
            <div class="confirm-container-body">
                <img class="confirm-type-icon" :src="icon">
                <div class="confirm-content-box">
                    <h5>{{ title }}</h5>
                    <div class="confirm-body-message" v-html="message"></div>
                </div>
            </div>
            <div style="text-align: right">
                <el-button v-if="cancelButtonShow" @click="cancel">{{ cancelButtonText }}</el-button>
                <el-button type="primary" @click="confirm">{{ confirmButtonText }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isShow: false,
            type: 'warning',
            title: '提示',
            message: '',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            cancelButtonShow: true,
            closeOnClickModal: true,
            closeImageShow: true,
            modalClassName: ''
        }
    },
    computed: {
        icon() {
            return require(`@/assets/img/toast_${this.type}.png`)
        }
    },
    methods: {
        cancel() {
            
        },
        confirm() {
            
        },
        show() {
            this.isShow = true
            document.body.style.height = '100%'
            document.body.style.overflow = 'hidden'
        },
        hide() {
            this.isShow = false
            document.body.style.height = ''
            document.body.style.overflow = ''
        },
        clickModal() {
            if (this.closeOnClickModal) this.hide()
        }
    }
}
</script>

<style lang="scss" scoped>
.vip-expired-tips {
    z-index: 9999;
}
.confirm-container {
    width: 430px;
    padding: 25px;
    background: #FFF;
    border-radius: 4px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    .confirm-close-button {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
    .confirm-container-body {
        display: flex;
        .confirm-type-icon {
            width: 24px;
            height: 24px;
            margin: 0 10px 0 0;
            position: relative;
            top: -2px;
        }
        .confirm-content-box {
            flex: 1;
            .confirm-body-message {
                color: #666;
                margin: 10px 0 20px;
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
}
</style>