import wx from 'weixin-webview-jssdk'
const { postMessage } = wx.miniProgram

export const postMsg = ({type, value}) => {
  const obj = {
    type,
    value
  }
  if (typeof value === 'object') {
    obj.value = JSON.stringify(value)
  }
  if (window.__wxjs_environment === 'miniprogram') {
    postMessage({ data: obj })
  } else {
    window.parent.postMessage(obj, process.env.VUE_APP_postMsgUrl)
  }
}
