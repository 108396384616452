import Vue from 'vue'
import { postMsg } from '@/utils'

function eventTracking(id) {
    postMsg({
        type: 'log',
        value: {
            id
        }
    })
}
export { eventTracking }
Vue.prototype.$log = eventTracking

export default {
    bind(el, binding) {
        el.eventTrackingFn = () => {
            eventTracking(binding.arg)
        }
        el.addEventListener('click', el.eventTrackingFn)
    },
    unbind(el) {
        el.removeEventListener('click', el.eventTrackingFn)
    },
    install(Vue) {
        Vue.directive('log', {
            bind: this.bind,
            unbind: this.unbind
        })
    }
}