import Vue from 'vue'
import toast from './Toast'

let seed = 0
const ToastConstructor = Vue.extend(toast)

const Toast = msg => {
    isString(msg)
    const instance = new ToastConstructor()
    instance.id = 'Toast_' + seed++
    instance.msg = msg
    const dom = instance.$mount().$el
    dom.style.top = (seed * 70) + 'px'
    document.body.appendChild(dom)
    removeToast(instance)
    return instance
}
['success', 'warning', 'error'].forEach(type => {
    Toast[type] = msg => {
        isString(msg)
        Toast(msg).type = type
    }
})
function isString(msg) {
    if (typeof msg !== 'string') throw new Error('The Toast value must be a string.')
}
function removeToast(instance) {
    setTimeout(() => {
        instance.close()
        if (seed > 0) seed--
    }, 2000)
}
Vue.prototype.$toast = Toast
export default Toast