import router from "./router";
import wx from 'weixin-webview-jssdk'
import toast from '@/plugins/Toast'
router.beforeEach((to, from, next) => {
  const oldToken = sessionStorage.getItem('token')
  const oldUid = sessionStorage.getItem('uid')
  const newToken = to.query.token
  const newUid = to.query.uid
  if ((!oldToken && !newToken) || (!oldUid && !newUid)) {
    let obj = {
      type: 'noToken', // 事件名
      value: '' // 数据，只能是字符串
    }
    if (window.__wxjs_environment === 'miniprogram') {
      const { navigateBack } = wx.miniProgram
      toast.warning('未获取登录信息')
      setTimeout(() => {
        navigateBack()
      }, 1000)
    } else {
      window.parent.postMessage(obj, '*')
    }
  } else if (newToken && newUid) {
    sessionStorage.setItem('token', newToken)
    sessionStorage.setItem('uid', newUid)
    next()
  } else if ((!newToken || !newUid) && oldToken && oldUid) {
    next()
  }
})