import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/list',
    name: 'List',
    component: () => import('@/views/list/list'),
  },
  {
    path: '/depList',
    name: 'DepList',
    component: () => import('@/views/depList/depList'),
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('@/views/detail/detail'),
  }
]

const router = new VueRouter({
  mode: 'history',
  home: process.env.BASE_URL,
  routes
})

export default router
