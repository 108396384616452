import Vue from 'vue'
import confirm from './Confirm'

const Confirm = options => {
    if (typeof options === 'string') options = { message: options }
    return new Promise((resolve, reject) => {
        const ConfirmConstructor = Vue.extend(confirm)
        const instance = new ConfirmConstructor({
            el: document.createElement('div')
        })
        document.body.appendChild(instance.$el)
        instance.$forceUpdate()
        instance.show()
        Object.assign(instance.$data, options)
        instance.confirm = () => {
            resolve()
            instance.hide()
        }
        instance.cancel = () => {
            reject()
            instance.hide()
        }
    })
}

Vue.prototype.$confirm = Confirm
export default Confirm