import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './plugins/index'
import './utils/breadcrumb'

import './assets/css/reset.css'
import './assets/css/style.scss'
import './assets/css/element-variables.scss'

import './permission'

import log from '@/utils/log'
Vue.use(log)
import clickoutside from '@/utils/clickoutside'
Vue.use(clickoutside)

Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }
import {
    Loading,
    Input,
    Button,
    Autocomplete,
    Select,
    Option,
    Checkbox,
    CheckboxGroup,
    Radio,
    RadioGroup,
    Table,
    TableColumn,
} from 'element-ui'
Vue.prototype.$loading = Loading.service
Vue.use(Loading.directive)
Vue.use(Input)
Vue.use(Button)
Vue.use(Autocomplete)
Vue.use(Select)
Vue.use(Option)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Radio)
Vue.use(RadioGroup);
Vue.use(Table)
Vue.use(TableColumn)

Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()





new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
