<template>
    <transition name="zoom-in-top">
        <div class="Toast" :id="id" v-if="isShow">
            <img class="icon" :src="icon">
            <span>{{ msg }}</span>
        </div>
    </transition>
</template>

<script>
export default {
    data() {
        return {
            id: '',
            msg: '',
            type: 'success',
            isShow: true
        }
    },
    computed: {
        icon() {
            return require(`@/assets/img/toast_${this.type}.png`)
        }
    },
    methods: {
        close() {
            this.isShow = false
        }
    }
}
</script>

<style lang="scss" scoped>
.Toast {
    max-width: 400px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    margin: auto;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: #666;
    background: #FFFFFF;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.5);
    z-index: 9999;
    .icon {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
}
</style>